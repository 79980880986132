<template>
  <section class="certificates">
    <h2 class="text-subheader">{{ title }}</h2>
    <div class="wrapper">
      <ClientOnly>
        <Swiper
          class="certificates-slider"
          :modules="[Pagination, Navigation]"
          :slides-per-view="3"
          pagination
          navigation
          :space-between="16"
          create-elements
          :auto-height="false"
          loop
          :breakpoints="breakpoints"
        >
          <SwiperSlide
            v-for="(item, index) in media"
            :key="index"
            class="certificates-slider__slide"
            @click="openMediaModal(index)"
          >
            <UIPictureTag
              :image-webp="`${url}${item.image.webp}`"
              :image-seo="item.image_seo"
              :image-original="`${url}${item.image.source}`"
              is-visible
            />
          </SwiperSlide>
        </Swiper>
      </ClientOnly>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import type { ImageItem, ImageSEO } from '~/types/pages';
import { computed, onUnmounted, useMediaModal, useRuntimeConfig } from '#imports';
import './styles.scss';

interface Props {
  title: string;
  media: {
    image: ImageItem;
    image_seo: ImageSEO;
  }[];
}

const props = defineProps<Props>();

const url = useRuntimeConfig().public.siteUrl;

const dataSource = computed(() =>
  props.media?.map(item => {
    return {
      src: `${url}${item.image.original}`,
      alt: item.image_seo.image_alt,
      title: item.image_seo.image_title,
    };
  }),
);

const { destroyMediaModal, openMediaModal } = useMediaModal(dataSource.value);

const breakpoints = {
  521: {
    slidesPerView: 3,
    spaceBetween: 32,
    centeredSlides: false,
  },
};

onUnmounted(() => destroyMediaModal());
</script>
